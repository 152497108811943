<template>

  <div class="px-5" id="page-transactions">
    <h5 class="mb-6 ml-2">Transaktioner</h5>

    <b-card
      title=""
      class="mb-2"
      hide-footer
    >

        <v-tabs
            background-color="white"
            color="dark"
            left
        >
            <v-tab>Ej bokförda</v-tab>
            <v-tab>Bokförda</v-tab>
            <v-tab v-if="isTHS">THS Admin</v-tab>
            <v-tab v-if="isTHS">{{$t('COMMON.SETTINGS')}}</v-tab>


            <v-tab-item class='mt-8'>
              <b-card title="Bokföringsinställningar" v-if="false">
                <b-row>
                  <b-col>
                    <label class="radio radio-outline radio-success mb-3">
                      <input type="radio" v-model="form.method" value="kontant" />
                      <span class="mr-2"></span>
                      Kontantmetoden
                    </label>

                  </b-col>
                  <b-col>
                    <label class="radio radio-outline radio-success mb-3">
                      <input type="radio" v-model="form.method" value="faktura" />
                      <span class="mr-2"></span>
                      Fakturametoden
                    </label>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-form-group
                      id="lev_account"
                      label="Leverantörsskuldkonto (4 siffror)"
                      label-for="lev_account"
                      class="mt-8"
                    >
                      <b-form-input id="lev_account" v-model="form.lev_account" maxlength="4" :state="validLevAccount">
                      </b-form-input>
                      <b-form-text>Vanligtvis 2440</b-form-text>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group
                      id="type_account"
                      label="Tjänst/Varukonto (4 siffror)"
                      label-for="type_account"
                      class="mt-8"
                    >
                      <b-form-input id="type_account" v-model="form.type_account" maxlength="4" :state="validTypeAccount">
                      </b-form-input>
                      <b-form-text>T ex 4610, 6540</b-form-text>

                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col>
                    <b-form-group
                      id="bank_account"
                      label="Bankkonto (4 siffror)"
                      label-for="bank_account"
                      class="mt-8"
                    >
                      <b-form-input id="bank_account" v-model="form.bank_account" maxlength="4" :state="validBankAccount">
                      </b-form-input>
                      <b-form-text>Vanligtvis 1910, 1920, 1930</b-form-text>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group
                      id="type_account"
                      label="Tjänst/Varukonto (4 siffror)"
                      label-for="type_account"
                      class="mt-8"
                    >
                      <b-form-input id="type_account" v-model="form.type_account" maxlength="4" :state="validTypeAccount">
                      </b-form-input>
                      <b-form-text>T ex 2440</b-form-text>

                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <div class="d-flex align-items-center mb-8 mt-8">
                    <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
                      <input
                        type="checkbox"
                        name=""
                        v-model="form.use_vat"
                        @click="form.use_vat = !form.use_vat"
                      />
                      <span></span>
                    </label>
                    <span class="ml-3 cursor-pointer">Använd moms</span>
                  </div>
                  </b-col>
                  <b-col>

                  </b-col>
                </b-row>
                <b-row v-if="form.use_vat">
                  <b-col>
                    <b-form-group
                      id="vat_account"
                      label="Momskonto (4 siffror)"
                      label-for="vat_account"
                      class="mt-8"
                    >
                      <b-form-input id="vat_account" v-model="form.vat_account" maxlength="4" :state="validVATAccount">
                      </b-form-input>
                      <b-form-text>T ex 2641</b-form-text>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group
                      id="vat_pct"
                      label="Momssats"
                      label-for="vat_pct"
                      class="mt-8"
                    >
                      <b-form-select v-model="form.vat_pct" :options="vatOptions"></b-form-select>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col>
                    <b-form-group
                      id="num_transactions"
                      label="Antal att bokföra"
                      label-for="num_transactions"
                      class="mt-8"
                    >
                      <b-form-input :disabled="true" id="num_transactions" v-model="num_transactions">
                      </b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col>



                  </b-col>
                </b-row>

                <b-row>
                  <b-col>
                    <b-button variant="primary" class="ml-2" v-on:click="bookTransactions">Bokför valda transaktioner</b-button>
                  </b-col>

                  <b-col>
                  </b-col>

                </b-row>
              </b-card>

              <TransactionsSearchOptions
                :isNonInvoiced="true"
                @onSearch="onSearchNonInvoicedTransactions"
              />

              <TransactionsTable
                ref="nonbookedTransactions"
                @transactionsSelected="transactionsSelected"
              />
            </v-tab-item>

            <v-tab-item class='mt-8'>
              <TransactionsSearchOptions
                :isNonInvoiced="false"
                @onSearch="onSearchInvoicedTransactions"
              />
              <TransactionsTable
                ref="invoicedTransactions"
              />
            </v-tab-item>

            <v-tab-item  v-if="isTHS" class='mt-8'>
              <TransactionTHSAdminView />
            </v-tab-item>

            <v-tab-item  v-if="isTHS" class='mt-8'>
              <TransactionSettings />
            </v-tab-item>


        </v-tabs>
    </b-card>

  </div>
</template>

<script>

import axios from 'axios';
import { mapGetters } from 'vuex';
import TransactionTHSAdminView from '@/view/pages/ml/transactions/TransactionTHSAdminView.vue';
import TransactionsSearchOptions from '@/view/pages/ml/transactions/TransactionsSearchOptions.vue';
import TransactionsTable from '@/view/pages/ml/transactions/TransactionsTable.vue';
import TransactionSettings from '@/view/pages/ml/settings/TransactionSettings.vue';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';


export default {
  name: 'transactions',
  components: {
    TransactionsTable,
    TransactionsSearchOptions,
    TransactionTHSAdminView,
    TransactionSettings
  },
  mixins: [ toasts ],
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'isTHS']),
    validBankAccount() {
      return this.validBookAccount(this.form.bank_account);
    },
    validVATAccount() {
      if (!this.form.use_vat) {
        return null;
      }

      return this.validBookAccount(this.form.vat_account);
    },
    validTypeAccount() {
      return this.validBookAccount(this.form.type_account);
    }
  },
  mounted() {

    this.$nextTick(() => {
      this.loadUnbookedTransactions();
    });

  },
  data() {
    return {
      num_transactions: 0,
      vatOptions: [
        { text: '25%', value: 0.25 },
        { text: '12%', value: 0.25 },
        { text: '6%', value: 0.25 },
        { text: 'Momsfritt', value: null },
      ],
      form: {
        use_vat: false,
        vat_account: 0,
        bank_account: 0,
        type_account: 0,
        vat_pct: 0
      }
    };
  },
  watch: {
    currentCompanyId(newValue, oldvalue) {
      if (newValue) {
        this.loadTransactions();
      }
    },
    from_date(newValue, oldValue) {
      this.loadTransactions();
    },
    to_date(newValue, oldValue) {
      this.loadTransactions();
    },
  },
  methods: {
    validBookAccount(account) {
      const str = account + '';
      const isnum = /^\d+$/.test(str);

      return str.length === 4 && isnum;
    },
    transactionsSelected(transactions) {
      this.num_transactions = transactions.length;
      this.transactions = transactions;
    },
    bookTransactions() {
      const ids = this.transactions.map((v) => ( v.id ) );

      this.postBookTransactions(ids, this.form.method, this.form.lev_account, this.form.bank_account, this.form.type_account, this.form.use_vat, this.form.vat_account);
    },
    loadUnbookedTransactions() {
      this.$nextTick(() => {
        if (this.$refs['nonbookedTransactions']) {
          this.$refs['nonbookedTransactions'].loadUnbookedTransactions();
        }
      });
    },
    onSearchNonInvoicedTransactions(from_date, to_date) {
      this.$refs['nonInvoicedTransactions'].search(from_date, to_date, undefined, false);
    },
    onSearchInvoicedTransactions(ths_invoice_nr) {
      this.$refs['invoicedTransactions'].search(undefined, undefined, ths_invoice_nr, true);
    },
    createTransactionClicked() {
      this.scrollToEditTransaction();

      this.form = {};
    },
    deleteTransactionClicked(id) {
      axios
        .delete(`/transaction/${id}`)
        .then(res => {

          if (res.status === 204) {
            this.transactions = this.transactions.filter(item => item.id !== id);
          }
          else {
            this.toastr('danger', this.$t('COMMON.ERROR'), 'Fel att radera transaktion');
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte radera');
        });
    },
    selectTransactionClicked(id) {
      this.form = this.transactions.find(item => item.id === id);
    },
    async postBookTransactions(transaction_ids, method, lev_account, bank_account, type_account, use_vat, vat_account) {
      axios
        .post(`/transaction/book`, {
          transaction_ids,
          method,
          lev_account,
          bank_account,
          type_account,
          use_vat,
          vat_account
        })
        .then(res => {
          if (res.status === 201) {
            this.toastr('success', this.$t('COMMON.OK'), 'Transaktioner kommer bokföras');
          }
          else {
            this.toastr('danger', this.$t('COMMON.ERROR'), 'Går ej att bokföra');
          }
        })
        .catch(err => {
          console.error('/transaction/book error', err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte bokföra transaktioner');
        });
    },

    scrollToEditTransaction() {
      var that = this;
      setTimeout(function () {
        const el = that.$el.getElementsByClassName('scroll-to-container')[0];

        if (el) {
          const yOffset = -70;
          const y = el.getBoundingClientRect().top + window.pageYOffset + yOffset;

          window.scrollTo({ top: y, behavior: 'smooth' });
        }
      }, 100);
    },
    saveTransaction() {
      const loader = this.$loading.show();

      if (!this.form.shop_order_id) {
        this.toastr('danger', this.$t('COMMON.ERROR'), 'Måste ha order ID');
        return;
      }


      if (!this.form.id) {
        axios
          .post(`/transaction`, this.form)
          .then(res => {
            loader & loader.hide();
            if (res.status !== 201) {
              this.toastr('danger', this.$t('COMMON.ERROR'), 'Fel att skapa transaktion');
              return;
            }

            this.toastr('success', this.$t('COMMON.OK'), 'Transaktion har skapats');

            this.transactions.push(res.data);

            this.form = res.data;
          })
          .catch(err => {
            loader & loader.hide();
            console.error(err);
            this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte skapa transaktion');
          });
      }
      else {
        axios
          .put(`/transaction/${this.form.id}`, this.form)
          .then(res => {
            loader & loader.hide();
            if (res.status !== 204) {
              this.toastr('danger', this.$t('COMMON.ERROR'), 'Fel att uppdatera transaktion');
              return;
            }
            this.toastr('success', this.$t('COMMON.OK'), 'Transaktion har uppdaterats');

            const t = this.transactions.find(item => item.id === this.form.id);

            t.created_at = this.form.created_at;
            t.cost_sek = this.form.cost_sek;
            t.reference = this.form.reference;

          })
          .catch(err => {
            loader & loader.hide();
            console.error(err);
            this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte uppdatera transaktion');
          });
      }
    },

  }
};
</script>
